<template>
  <div v-if="isOpen">
    <slot />
  </div>
</template>
<script>
export default {
  inject: ['getOpen'],
  computed: {
    isOpen: (vm) => vm.getOpen()
  }
}
</script>
