var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ManageStreamsNearWordsSearch m-b-m",attrs:{"data-testid":"ManageStreamsNearWordsSearch"}},[_c('form-wrapper',{staticClass:"columns is-variable-grid is-1 is-mobile is-multiline",attrs:{"validator":_vm.validator}},[_c('form-field',{staticClass:"column is-12-mobile",attrs:{"name":"term1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var placeholder = ref.placeholder;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(validator.$model),expression:"validator.$model",modifiers:{"trim":true}}],staticClass:"input",attrs:{"placeholder":placeholder,"type":"text","name":"term1","data-testid":"NearWordsSearch__Term1"},domProps:{"value":(validator.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(validator, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]}}])}),_c('div',{staticClass:"column is-4-mobile"},[_vm._v(" "+_vm._s(_vm.$t('components.manage_streams_near_words_search.less_than'))+" ")]),_c('form-field',{staticClass:"is-width-24 is-8-mobile column",attrs:{"name":"distance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var placeholder = ref.placeholder;
return [_c('basic-select',{attrs:{"values":_vm.distanceValues,"name":"distance","data-testid":"NearWordsSearch__Distance","popper-class":"NearWordsSearch__Modal"},model:{value:(validator.$model),callback:function ($$v) {_vm.$set(validator, "$model", $$v)},expression:"validator.$model"}})]}}])}),_c('div',{staticClass:"column is-4-mobile"},[_vm._v(" "+_vm._s(_vm.$t('components.manage_streams_near_words_search.words_from'))+" ")]),_c('form-field',{staticClass:"column is-6-mobile",attrs:{"name":"term2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validator = ref.validator;
var placeholder = ref.placeholder;
return [_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(validator.$model),expression:"validator.$model",modifiers:{"trim":true}}],staticClass:"input",attrs:{"placeholder":placeholder,"type":"text","name":"term2","data-testid":"NearWordsSearch__Term2"},domProps:{"value":(validator.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(validator, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]}}])}),_c('div',{staticClass:"column is-narrow"},[_c('v-button',{staticClass:"is-plain is-primary is-large is-narrow",attrs:{"title":_vm.$t('general.remove'),"type":"button","data-testid":"NearWordsSearch__RemoveButton"},on:{"click":function($event){return _vm.$emit('remove')}}},[_c('v-icon',{attrs:{"type":"times"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }