<template>
  <li
    :id="id"
    role="button"
    @pointermove="onPointerMove"
    @click="onClick"
  >
    <slot :active="activeOptionId === id" />
  </li>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import uniqueId from 'lodash/uniqueId'

export default {
  inject: [
    'getButton',
    'getActiveOptionId',
    'close',
    'registerOption',
    'unregisterOption',
    'activateOption',
    'select'
  ],
  props: {
    value: {
      type: [Object, String, Number, Boolean],
      default: undefined
    }
  },
  data () {
    return {
      valueRef: {
        value: null
      }
    }
  },
  computed: {
    button: (vm) => vm.getButton(),
    activeOptionId: (vm) => vm.getActiveOptionId()
  },
  watch: {
    value: {
      handler: function (value) { this.valueRef.value = value },
      immediate: true
    }
  },
  created () {
    this.id = uniqueId('app-listbox-option-')
  },
  mounted () {
    this.registerOption(this.id, this.valueRef, this.$el)
  },
  beforeDestroy () {
    this.unregisterOption(this.id)
  },
  methods: {
    onPointerMove () {
      this.activateOption(this.id)
    },
    onClick () {
      this.select(this.value)
      this.close()
      this.$nextTick(() => this.button !== null && this.button.focus({ preventScroll: true }))
    }
  }
}
</script>
