<template>
  <ul
    v-if="isOpen"
    tabindex="0"
    @blur="onBlur"
    @keydown.esc="onEscape"
    @keydown.down.prevent="onDown"
    @keydown.up.prevent="onUp"
    @keydown.enter.prevent="onEnter"
  >
    <slot />
  </ul>
</template>
<script>
export default {
  inject: [
    'getButton',
    'getOpen',
    'getActiveOptionValue',
    'close',
    'registerMenu',
    'unregisterMenu',
    'activateOption',
    'activateNextOption',
    'activatePreviousOption',
    'select'
  ],
  computed: {
    button: (vm) => vm.getButton(),
    isOpen: (vm) => vm.getOpen(),
    activeOptionValue: (vm) => vm.getActiveOptionValue()
  },
  watch: {
    isOpen (value) {
      if (value) {
        this.$nextTick(() => this.registerMenu(this.$el))
      } else {
        this.unregisterMenu()
      }
    }
  },
  methods: {
    onBlur (event) {
      if (event.relatedTarget !== null && event.relatedTarget.isEqualNode(this.button)) return
      this.isOpen && this.close()
    },
    onEscape () {
      this.isOpen && this.close()
      this.$nextTick(() => this.button !== null && this.button.focus({ preventScroll: true }))
    },
    onDown () {
      this.activateNextOption()
    },
    onUp () {
      this.activatePreviousOption()
    },
    onEnter () {
      if (!this.isOpen) return
      if (this.activeOptionValue === null) return
      this.select(this.activeOptionValue)
      this.close()
      this.$nextTick(() => this.button !== null && this.button.focus({ preventScroll: true }))
    }
  }
}
</script>
