import Vue from 'vue'

const mod = (n, m) => ((n % m) + m) % m

const sortByDomNode = (nodes) => nodes.slice().sort((a, b) => {
  if (!(a.ref && b.ref)) return 0
  const position = a.ref.compareDocumentPosition(b.ref)
  if (position & Node.DOCUMENT_POSITION_FOLLOWING) return -1
  if (position & Node.DOCUMENT_POSITION_PRECEDING) return 1
  return 0
})

export default () => {
  const adjustState = (adjustment) => {
    adjustment = adjustment || ((i) => i)
    const activeOption = state.active === null ? null : state.options[state.active]
    const sortedOptions = sortByDomNode(adjustment(state.options.slice()))
    const activeOptionIndex = sortedOptions.indexOf(activeOption)
    return {
      options: sortedOptions,
      active: activeOptionIndex === -1 ? null : activeOptionIndex
    }
  }

  const state = Vue.observable({
    options: [],
    active: null
  })

  return {
    getActiveIndex: () => state.active,
    getActiveId: () => {
      if (state.active === null) return null
      return state.options[state.active].id
    },
    getActiveValue: () => {
      if (state.active === null) return null
      return state.options[state.active].value.value
    },
    register: (id, value, ref) => {
      const { options, active } = adjustState((options) => [...options, { id, value, ref }])
      state.options = options
      state.active = active
    },
    unregister: (id) => {
      const { options, active } = adjustState((options) => {
        const index = options.findIndex((a) => a.id === id)
        if (index !== -1) options.splice(index, 1)
        return options
      })
      state.options = options
      state.active = active
    },
    activateById: (id) => {
      const { options } = adjustState()
      state.options = options
      state.active = options.findIndex((option) => option.id === id)
    },
    activateByValue: (value) => {
      const { options } = adjustState()
      state.options = options
      state.active = options.findIndex((option) => option.value.value === value)
    },
    activateFirst: () => {
      const { options } = adjustState()
      state.options = options
      state.active = 0
    },
    activatePrevious: () => {
      const { options, active } = adjustState()
      state.options = options
      state.active = active === null ? options.length - 1 : mod(active - 1, options.length)
    },
    activateNext: () => {
      const { options, active } = adjustState()
      state.options = options
      state.active = active === null ? 0 : mod(active + 1, options.length)
    },
    activateLast: () => {
      const { options } = adjustState()
      state.options = options
      state.active = options.length - 1
    }
  }
}
