<template>
  <button
    :id="id"
    type="button"
    @pointermove="onPointerMove"
    @mousedown.prevent
    @click="onClick"
  >
    <slot :active="activeOptionId === id" />
  </button>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import uniqueId from 'lodash/uniqueId'

export default {
  inject: [
    'getActiveOptionId',
    'registerOption',
    'unregisterOption',
    'close',
    'activateOption',
    'select'
  ],
  props: {
    value: {
      type: [Object, String, Number, Boolean],
      default: undefined
    },
    index: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      valueRef: {
        value: null
      }
    }
  },
  computed: {
    activeOptionId: (vm) => vm.getActiveOptionId()
  },
  watch: {
    value: {
      handler: function (value) { this.valueRef.value = value },
      immediate: true
    }
  },
  created () {
    this.id = uniqueId('app-combobox-option-')
  },
  mounted () {
    this.registerOption(this.id, this.valueRef, this.$el)
  },
  beforeDestroy () {
    this.unregisterOption(this.id)
  },
  methods: {
    onPointerMove () {
      this.activateOption(this.id)
    },
    onClick () {
      this.select(this.value)
      this.close()
    }
  }
}
</script>
