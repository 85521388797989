<template>
  <ComboboxControl v-model="entity" :autocomplete="shouldAutocomplete" class="relative flex flex-col">
    <ComboboxInput ref="input" v-model="query" class="px-3 py-2 outline-none" />
    <ComboboxMenu class="z-10 absolute top-full right-0 left-0 mt-2 flex flex-col bg-white border rounded shadow border-gray-300">
      <ComboboxOption
        v-slot="{ active }"
        :key="null"
        :value="query"
        :index="0"
        class="text-left"
      >
        <div class="cursor-pointer p-2" :class="{ 'bg-gray-50': active }">
          "{{ query }}"
        </div>
      </ComboboxOption>
      <ComboboxOption
        v-for="(entity, index) in entities"
        v-slot="{ active }"
        :key="entity"
        :value="entity"
        :index="index + 1"
        class="text-left"
      >
        <div class="cursor-pointer p-2 font-bold" :class="{ 'bg-gray-50': active }">
          {{ entity }}
        </div>
      </ComboboxOption>
    </ComboboxMenu>
  </ComboboxControl>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import _debounce from 'lodash/debounce'
import ComboboxControl from '@hypefactors/shared/js/components/ui/ComboboxControl.vue'
import ComboboxInput from '@hypefactors/shared/js/components/ui/ComboboxInput.vue'
import ComboboxMenu from '@hypefactors/shared/js/components/ui/ComboboxMenu.vue'
import ComboboxOption from '@hypefactors/shared/js/components/ui/ComboboxOption.vue'

export default {
  components: {
    ComboboxControl,
    ComboboxInput,
    ComboboxMenu,
    ComboboxOption
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      query: '',
      entities: []
    }
  },
  computed: {
    shouldAutocomplete: (vm) => vm.autocomplete && vm.type !== undefined,
    entity: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    entity: {
      handler () {
        this.query = this.entity
      },
      immediate: true
    },
    query (query) {
      if (query === '') this.$emit('input', '')
      this.shouldAutocomplete ? this.debouncedFetchEntities() : (this.entity = query)
    }
  },
  created () {
    this.debouncedFetchEntities = _debounce(this.fetchEntities, 300)
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    fetchEntities () {
      if (this.query.trim() === '') return
      if (this.type === '') return

      const payload = { params: { name: this.query.trim() } }
      if (this.type) payload.params.type = this.type

      this.$api.get('/entities', payload)
        .then((response) => response.data.data)
        .then((entities) => { this.entities = [...new Set(entities.map((entity) => entity.name))] })
    }
  }
}
</script>
