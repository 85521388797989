<template>
  <div>
    <slot />
  </div>
</template>
<script>
import Vue from 'vue'
import useOpenClosedState from './openClosedState'
import useOptionState from './optionState'

export default {
  props: {
    value: {
      type: [Object, String, Number, Boolean],
      default: undefined
    }
  },
  provide () {
    const { getOpen, open, close, toggle } = useOpenClosedState()

    const {
      getActiveId: getActiveOptionId,
      getActiveValue: getActiveOptionValue,
      register: registerOption,
      unregister: unregisterOption,
      activateById: activateOptionById,
      activateByValue: activateOptionByValue,
      activateFirst: activateFirstOption,
      activatePrevious: activatePreviousOption,
      activateNext: activateNextOption
    } = useOptionState()

    const state = Vue.observable({
      button: null,
      menu: null
    })

    return {
      getButton () {
        return state.button
      },
      getMenu () {
        return state.menu
      },
      registerButton (el) {
        state.button = el
      },
      unregisterButton () {
        state.button = null
      },
      registerMenu (el) {
        state.menu = el
      },
      unregisterMenu () {
        state.menu = null
      },
      getOpen,
      getActiveOptionId,
      getActiveOptionValue,
      open: () => {
        open()
        this.$nextTick(() => this.value === undefined ? activateFirstOption() : activateOptionByValue(this.value))
      },
      close,
      toggle,
      registerOption,
      unregisterOption,
      activateOption: activateOptionById,
      activatePreviousOption: () => getOpen() && activatePreviousOption(),
      activateNextOption: () => getOpen() && activateNextOption(),
      select: (value) => this.$emit('input', value)
    }
  }
}
</script>
