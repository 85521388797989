<template>
  <div
    class="ManageStreamsNearWordsSearch m-b-m"
    data-testid="ManageStreamsNearWordsSearch"
  >
    <form-wrapper :validator="validator" class="columns is-variable-grid is-1 is-mobile is-multiline">
      <form-field v-slot="{ validator, placeholder }" name="term1" class="column is-12-mobile">
        <input
          v-model.trim="validator.$model"
          :placeholder="placeholder"
          class="input"
          type="text"
          name="term1"
          data-testid="NearWordsSearch__Term1"
        >
      </form-field>
      <div class="column is-4-mobile">
        {{ $t('components.manage_streams_near_words_search.less_than') }}
      </div>
      <form-field v-slot="{ validator, placeholder }" name="distance" class="is-width-24 is-8-mobile column">
        <basic-select
          v-model="validator.$model"
          :values="distanceValues"
          name="distance"
          data-testid="NearWordsSearch__Distance"
          popper-class="NearWordsSearch__Modal"
        />
      </form-field>
      <div class="column is-4-mobile">
        {{ $t('components.manage_streams_near_words_search.words_from') }}
      </div>
      <form-field v-slot="{ validator, placeholder }" name="term2" class="column is-6-mobile">
        <input
          v-model.trim="validator.$model"
          :placeholder="placeholder"
          class="input"
          type="text"
          name="term2"
          data-testid="NearWordsSearch__Term2"
        >
      </form-field>
      <div class="column is-narrow">
        <v-button
          :title="$t('general.remove')"
          class="is-plain is-primary is-large is-narrow"
          type="button"
          data-testid="NearWordsSearch__RemoveButton"
          @click="$emit('remove')"
        >
          <v-icon type="times" />
        </v-button>
      </div>
    </form-wrapper>
  </div>
</template>

<script>
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * @module ManageStreamsNearWordsSearch
 */
export default {
  name: 'ManageStreamsNearWordsSearch',
  props: {
    /**
     * @type {{ term1: String, term2: String, distance: Number }}
     */
    value: {
      type: Object,
      required: true
    },
    validator: {
      type: Object,
      required: true
    }
  },
  computed: {
    valueLocal: generateComputedSetterWithEmit('value', 'input'),
    distanceValues: () => Array.from(Array(10).keys()).map(v => v + 1)
  }
}
</script>
