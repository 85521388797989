<template>
  <div class="flex flex-col gap-5">
    <KeyphraseSearch
      v-for="(keyphrase, index) in keyphrases"
      ref="searches"
      :key="keyphrase.id"
      :keyphrase="keyphrase"
      :cardinality="cardinality"
      @change="(value) => onChange(index, value)"
      @clone="(value) => onClone(value)"
      @delete="(id) => onDelete(id)"
    />
    <div v-if="cardinality === undefined || cardinality > 1">
      <button type="button" class="px-2 py-1 border border-white rounded bg-black uppercase font-bold text-white" @click="onAdd">
        Add search
      </button>
    </div>
  </div>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import uniqueId from 'lodash/uniqueId'
import KeyphraseSearch from './KeyphraseSearch.vue'

export default {
  components: {
    KeyphraseSearch
  },
  model: {
    event: 'change'
  },
  props: {
    cardinality: {
      type: Number,
      default: undefined
    },
    storedKeyphrases: {
      type: Array,
      default: undefined
    }
  },
  data () {
    return {
      keyphrases: [{ id: uniqueId('keyphrase-search-'), keyword: '', type: 'entities_ALL' }]
    }
  },
  watch: {
    storedKeyphrases (keyphrases) {
      this.keyphrases = keyphrases.length > 0
        ? keyphrases.map((keyphrase) => ({ id: uniqueId('keyphrase-search-'), keyword: keyphrase.keyword, type: keyphrase.type }))
        : [{ id: uniqueId('keyphrase-search-'), keyword: '', type: 'entities_ALL' }]
    },
    keyphrases (keyphrases) {
      this.$emit('change', this.keyphrases
        .filter(
          (keyphraseA, indexA, selfA) =>
            keyphraseA.keyword.trim() !== '' &&
              selfA.findIndex(
                (keyphraseB, indexB) =>
                  (keyphraseB.type === undefined
                    ? keyphraseB.keyword.trim() === keyphraseA.keyword.trim()
                    : keyphraseB.keyword.toLowerCase().trim() === keyphraseA.keyword.toLowerCase().trim()) &&
                  ((keyphraseB.type === keyphraseA.type &&
                    indexB > indexA) ||
                      (keyphraseB.type === 'entities_ALL' &&
                        keyphraseA.type !== 'entities_ALL' &&
                        keyphraseA.type !== undefined))
              ) === -1
        )
        .map((keyphrase) => ({ keyword: keyphrase.keyword, type: keyphrase.type }))
      )
    }
  },
  methods: {
    onAdd () {
      this.keyphrases = this.keyphrases
        .filter((keyphrase) => keyphrase.keyword.trim() !== '')
        .concat({ id: uniqueId('keyphrase-search-'), keyword: '', type: 'entities_ALL' })

      this.$nextTick(() => this.$refs.searches[this.$refs.searches.length - 1].focus())
    },
    onChange (index, value) {
      this.$set(this.keyphrases, index, value)
    },
    onClone ({ keyword, type }) {
      this.keyphrases.push({ id: uniqueId('keyphrase-search-'), keyword, type })
    },
    onDelete (id) {
      this.keyphrases = this.keyphrases.filter((keyphrase) => keyphrase.id !== id)
    }
  }
}
</script>
