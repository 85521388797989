import Vue from 'vue'

export default () => {
  const state = Vue.observable({ open: false })

  return {
    getOpen: () => state.open,
    open: () => (state.open = true),
    close: () => (state.open = false),
    toggle: () => (state.open = !state.open)
  }
}
