<template>
  <el-collapse-item
    :title="$t('general.additional_information')"
    :name="sectionName"
    class="ManageStreams-section ManageAdditionalInfo"
  >
    <slot />
  </el-collapse-item>
</template>

<script>

export default {
  data () {
    return {
      sectionName: 2
    }
  }
}
</script>
