<template>
  <el-dialog
    :title="$t('components.stream_brand_change_modal.title')"
    :visible.sync="isVisibleLocal"
    width="50%"
    class="StreamBrandChangeModal"
  >
    <basic-select
      v-model="brand"
      :values="brands"
      key-prop="id"
      value-prop="id"
      label-prop="name"
      class="ChangeStreamBrandDropdown"
      popper-class="ChangeStreamBrandDropdownDialog"
    />
    <div slot="footer">
      <v-button
        :loading="isLoading"
        class="is-primary"
        data-testid="ChangeStreamBrandAction"
        @click="changeStreamBrand"
      >
        {{ $t('forms.save') }}
      </v-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { StreamsApiService } from '@hypefactors/shared/js/services/api/StreamsService'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

/**
 * Generates a modal with a brands dropdown to change the brand of a stream
 * @module StreamBrandChangeModal
 */
export default {
  name: 'StreamBrandChangeModal',

  props: {
    visible: {
      type: Boolean,
      required: true
    },
    streamId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      brand: null,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters(['brands']),
    isVisibleLocal: generateComputedSetterWithEmit('visible', 'update:visible')
  },

  watch: {
    visible (newVal) {
      if (newVal) {
        this.brand = this.activeBrandId
      }
    }
  },

  methods: {
    async changeStreamBrand () {
      this.isLoading = true

      try {
        await StreamsApiService.transferOwnership(this.streamId, this.brand)
        this.$notify.success(this.$t('success.stream_brand_changed'))
        this.isVisibleLocal = false
        this.$emit('change', this.brand)
      } catch (err) {
        this.$displayRequestError(err, this.$t('errors.stream_brand_change_unsuccessful'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
