<template>
  <div
    :id="id"
    role="button"
    tabindex="0"
    @click="onClick"
    @keydown.enter.prevent="onEnter"
  >
    <slot :open="isOpen" />
  </div>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import uniqueId from 'lodash/uniqueId'

export default {
  inject: [
    'getMenu',
    'getOpen',
    'registerButton',
    'unregisterButton',
    'open',
    'close'
  ],
  computed: {
    menu: (vm) => vm.getMenu(),
    isOpen: (vm) => vm.getOpen()
  },
  created () {
    this.id = uniqueId('app-listbox-button-')
  },
  mounted () {
    this.registerButton(this.$el)
  },
  beforeDestroy () {
    this.unregisterButton()
  },
  methods: {
    onClick (event) {
      if (this.isOpen) {
        this.close()
        this.$nextTick(() => this.$el.focus({ preventScroll: true }))
      } else {
        event.preventDefault()
        this.open()
        requestAnimationFrame(() => {
          this.menu !== null && this.menu.focus({ preventScroll: true })
        })
      }
    },
    onEnter () {
      this.open()
      requestAnimationFrame(() => {
        this.menu !== null && this.menu.focus({ preventScroll: true })
      })
    }
  }
}
</script>
