<template>
  <input
    type="text"
    :value="value"
    @input="onInput"
    @blur="onBlur"
    @keydown.esc="onEscape"
    @keydown.down.prevent="onDown"
    @keydown.up.prevent="onUp"
    @keydown.enter="onEnter"
    @click="onClick"
  >
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
export default {
  inject: [
    'getAutocomplete',
    'getOpen',
    'getActiveOptionValue',
    'open',
    'close',
    'toggle',
    'activatePreviousOption',
    'activateNextOption',
    'select'
  ],
  props: {
    value: {
      type: [Object, String, Number, Boolean],
      default: undefined
    }
  },
  computed: {
    autocomplete: (vm) => vm.getAutocomplete(),
    isOpen: (vm) => vm.getOpen(),
    activeOptionValue: (vm) => vm.getActiveOptionValue()
  },
  watch: {
    isOpen (value) {
      !value && this.value.length > 0 && this.select(this.value)
    }
  },
  methods: {
    focus () {
      this.$el.focus()
    },
    onInput (event) {
      this.autocomplete &&
        this.isOpen &&
        event.target.value.trim().length === 0 &&
        this.close()

      this.autocomplete &&
        !this.isOpen &&
        event.target.value.trim().length > 0 &&
        this.open()

      this.$emit('input', event.target.value)
    },
    onBlur () {
      this.isOpen && this.close()
    },
    onEscape (event) {
      event.target.blur()
    },
    onDown () {
      this.activateNextOption()
    },
    onUp () {
      this.activatePreviousOption()
    },
    onEnter () {
      if (!this.isOpen) return
      if (this.activeOptionValue === null) return

      this.select(this.activeOptionValue)
      this.close()
    },
    onClick (event) {
      this.autocomplete &&
        event.target.value.trim().length > 0 &&
        this.toggle()
    }
  }
}
</script>
