<template>
  <el-collapse-item
    :title="$t('general.basic_information')"
    :name="sectionName"
    class="ManageStreams-section ManageDefinitions"
  >
    <slot />
  </el-collapse-item>
</template>

<script>

export default {
  data () {
    return {
      sectionName: 1
    }
  }
}
</script>
