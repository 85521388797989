<template>
  <ListboxControl v-model="type" class="relative flex flex-col">
    <ListboxButton v-slot="{ open }" class="flex items-center justify-between p-2 border-r bg-gray-50">
      <div class="flex-1 flex items-center gap-2">
        <div class="w-7 flex justify-center">
          <EntityTypeIcon :type="type" :class="{ 'text-red-700': open }" class="h-5" />
        </div>
        <span>{{ types.find((value) => value.name === type).title }}</span>
      </div>
      <ChevronUpIcon v-if="open" class="h-5" />
      <ChevronDownIcon v-else class="h-5" />
    </ListboxButton>
    <ListboxMenu class="z-10 absolute top-full right-0 left-0 mt-1 flex flex-col rounded shadow bg-gray-50">
      <ListboxOption
        v-for="({ name, title }, index) in types"
        v-slot="{ active }"
        :key="name"
        :value="name"
      >
        <div
          class="cursor-pointer flex items-center gap-2 p-2"
          :class="{ 'bg-black text-white hover:bg-black': name === type, 'bg-gray-100': active && name !== type }"
        >
          <div class="w-7 flex justify-center" :class="{ 'text-red-700': name === type }">
            <EntityTypeIcon :type="name" class="h-5" />
          </div>
          <span>{{ title }}</span>
        </div>
      </ListboxOption>
    </ListboxMenu>
  </ListboxControl>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import ListboxControl from '@hypefactors/shared/js/components/ui/ListboxControl.vue'
import ListboxButton from '@hypefactors/shared/js/components/ui/ListboxButton.vue'
import ListboxMenu from '@hypefactors/shared/js/components/ui/ListboxMenu.vue'
import ListboxOption from '@hypefactors/shared/js/components/ui/ListboxOption.vue'
import ChevronUpIcon from '@hypefactors/shared/js/components/ui/icons/ChevronUpIcon.vue'
import ChevronDownIcon from '@hypefactors/shared/js/components/ui/icons/ChevronDownIcon.vue'
import EntityTypeIcon from './EntityTypeIcon.vue'

export default {
  components: {
    ListboxControl,
    ListboxButton,
    ListboxMenu,
    ListboxOption,
    ChevronUpIcon,
    ChevronDownIcon,
    EntityTypeIcon
  },
  props: {
    value: {
      type: String,
      default: undefined
    }
  },
  computed: {
    type: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  created () {
    this.types = [
      { name: 'entities_ALL', title: this.$t('components.streams.entity_type.all') },
      { name: 'entities_PERS', title: this.$t('components.streams.entity_type.person') },
      { name: 'entities_PROD', title: this.$t('components.streams.entity_type.product') },
      { name: 'entities_ORG', title: this.$t('components.streams.entity_type.organisation') },
      { name: 'entities_LOC', title: this.$t('components.streams.entity_type.location') },
      { name: 'entities_EVENT', title: this.$t('components.streams.entity_type.event') },
      { name: undefined, title: this.$t('components.streams.entity_type.free_text') }
    ]
  }
}
</script>
