<template>
  <el-collapse-item
    v-loading="isLoading"
    :name="sectionName"
    class="ManageStreams-section ManageMedias"
    title="Media monitoring"
  >
    <div class="columns">
      <div class="column is-2">
        <p class="m-b-m">
          {{ $t('components.streams.manage_medias.sidebar_description_1') }}
        </p>
        <p>{{ $t('components.streams.manage_medias.sidebar_description_2') }}</p>
      </div>
      <div class="column is-10">
        <form-field
          :validator="v"
          attribute="general.media"
        >
          <div
            class="ManageStreams-medias columns is-multiline is-mobile p-t-m p-l-m"
            data-testid="mediaStreamMedias"
          >
            <div
              v-for="media in mediaTypesFiltered"
              :key="media.id"
              class="column is-narrow has-text-centered"
            >
              <div
                :class="[`ManageStreams-media--${media.id}`, {'is-selected': mediaTypes.includes(media.id)}, { notAuthenticated: requiresSocialToken(media), 'is-disabled': isDisabled(media.id) }]"
                class="ManageStreams-media"
                @click="handleSelectMedia(media)"
              >
                <div class="ManageStreams-media__icon icon is-large is-round">
                  <el-tooltip
                    :content="$t('components.streams.manage_medias.requires_authorization')"
                    effect="dark"
                    placement="top-start"
                  >
                    <div
                      v-show="requiresSocialToken(media)"
                      class="ManageStreams-media__token-warning icon is-round has-background-danger"
                    >
                      !
                    </div>
                  </el-tooltip>

                  <clipping-type-icon :type="media.id" />
                </div>
                <div>{{ media.name }}</div>
              </div>
            </div>
          </div>
        </form-field>
      </div>
    </div>
  </el-collapse-item>
</template>
<script>
import SocialTokenManagerMixin from '@/components/social/SocialTokenManagerMixin'
import { UNFOLLOWABLE_MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'
import { generateComputedSetterWithEmit } from '@hypefactors/shared/js/utils/componentUtilities'

export default {
  name: 'ManageMedias',
  mixins: [SocialTokenManagerMixin],
  props: {
    value: {
      type: Array,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sectionName: 0,
      mediasToFollow: [],
      isLoading: false,
      isWaitingForToken: false
    }
  },
  computed: {
    mediaTypes: generateComputedSetterWithEmit('value', 'input'),
    mediaTypesFiltered () {
      return this.mediasToFollow.filter(m => !UNFOLLOWABLE_MEDIA_TYPES.includes(m.id))
    },
    mediaTypePermissions () {
      return this.authorize('stream.types')
    },
    allowedMediaTypes () {
      return this.mediaTypePermissions.only || []
    },
    minimumRequirements () {
      return this.mediaTypePermissions.at_least || []
    }
  },
  async mounted () {
    await this.fetchMediaTypes()
    if (this.minimumRequirements.length) {
      this.mediaTypes = this.minimumRequirements.slice(0)
    }
  },
  methods: {
    requiresSocialToken (media) {
      return this.requiresSocialTokenForSomeNetworks(media)
    },
    fetchMediaTypes () {
      this.isLoading = true
      this.$api.get('clippings/media-types')
        .then(response => {
          this.isLoading = false
          this.mediasToFollow = response.data.data
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
        })
    },
    async handleSelectMedia (media) {
      if (this.shouldUpgrade(media.id)) {
        return this.$emit('show-upgrade-dialog')
      }
      if (this.isRssIncompatible(media.id)) {
        return this.$alert(this.$t('components.streams.manage_medias.rss_enabled_warning'))
      }
      if (this.isRequired(media.id)) {
        this.$notify.warning(this.$t('pages.streams.manage_streams.this_media_type_is_required'))
        return
      }
      if (!this.requiresSocialToken(media)) {
        return this.selectMedia(media)
      }
      try {
        await this.$confirm(this.$t('components.streams.manage_medias.requires_authorization_confirm'), {
          confirmButtonText: this.$t('forms.ok'),
          cancelButtonText: this.$t('forms.cancel'),
          type: 'warning'
        })
        await this.requestSocialToken(media.id)
        if (this.mediaTypes.includes(media.id)) return
        this.selectMedia(media)
      } catch (err) {
        if (err === 'cancel') return
        if (err.message === 'User closed window without it being ready') return
        this.$handleError(err)
      }
    },
    shouldUpgrade (type) {
      return this.allowedMediaTypes.length && !this.allowedMediaTypes.includes(type)
    },
    isRssIncompatible (type) {
      return this.form.auto_rss && type !== 'web'
    },
    isDisabled (type) {
      return this.isRssIncompatible(type) || this.shouldUpgrade(type) || false
    },
    isRequired (type) {
      return this.minimumRequirements.includes(type)
    },
    selectMedia ({ id: media }) {
      let mediaTypes = this.mediaTypes.slice(0)
      const index = mediaTypes.indexOf(media)
      const exists = index !== -1
      exists
        ? mediaTypes.splice(index, 1)
        : mediaTypes.push(media)
      this.mediaTypes = mediaTypes
      this.v.$touch()
      this.$emit('change', { media_type: media, is_activated: !exists })
    }
  }
}
</script>
