<template>
  <div>
    <slot />
  </div>
</template>
<script>
import useOpenClosedState from './openClosedState'
import useOptionState from './optionState'

export default {
  props: {
    value: {
      type: [Object, String, Number, Boolean],
      default: undefined
    },
    autocomplete: {
      type: Boolean,
      default: true
    }
  },
  provide () {
    const { getOpen, open, close, toggle } = useOpenClosedState()

    const {
      getActiveIndex: getActiveOptionIndex,
      getActiveId: getActiveOptionId,
      getActiveValue: getActiveOptionValue,
      register: registerOption,
      unregister: unregisterOption,
      activateById: activateOption,
      activateFirst: activateFirstOption,
      activatePrevious: activatePreviousOption,
      activateNext: activateNextOption,
      activateLast: activateLastOption
    } = useOptionState()

    return {
      getAutocomplete: () => this.autocomplete,
      getOpen,
      getActiveOptionIndex,
      getActiveOptionId,
      getActiveOptionValue,
      open: () => {
        open()
        this.$nextTick(() => activateFirstOption())
      },
      close,
      toggle,
      registerOption,
      unregisterOption,
      activateOption: (id) => getOpen() && activateOption(id),
      activateFirstOption: () => getOpen() && activateFirstOption(),
      activatePreviousOption: () => getOpen() && activatePreviousOption(),
      activateNextOption: () => getOpen() && activateNextOption(),
      activateLastOption: () => getOpen() && activateLastOption(),
      select: (value) => this.$emit('input', value)
    }
  }
}
</script>
