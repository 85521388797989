<template>
  <div class="flex items-center border border-gray-300">
    <EntityTypePicker v-model="type" class="mr-1 w-44" />
    <EntityPicker
      ref="entityPicker"
      v-model="keyword"
      :autocomplete="false"
      :type="type"
      class="flex-1"
    />
    <ListboxControl v-if="cardinality === undefined || cardinality > 1" class="relative" @input="onAction">
      <ListboxButton tabindex="-1">
        <KebabMenuIcon class="mx-1 h-5" />
      </ListboxButton>
      <ListboxMenu class="cursor-pointer z-10 absolute top-full -right-1 mt-1 w-20 flex flex-col border rounded shadow bg-gray-50">
        <ListboxOption v-slot="{ active }" value="clone" class="flex flex-col">
          <div class="p-2" :class="{ 'bg-gray-100': active }">
            Clone
          </div>
        </ListboxOption>
        <ListboxOption v-slot="{ active }" value="delete" class="flex flex-col">
          <div class="p-2" :class="{ 'bg-gray-100': active }">
            Delete
          </div>
        </ListboxOption>
      </ListboxMenu>
    </ListboxControl>
  </div>
</template>
<style scoped lang="postcss">
@tailwind base;
@tailwind components;
@tailwind utilities;
</style>
<script>
import ListboxControl from '@hypefactors/shared/js/components/ui/ListboxControl.vue'
import ListboxButton from '@hypefactors/shared/js/components/ui/ListboxButton.vue'
import ListboxMenu from '@hypefactors/shared/js/components/ui/ListboxMenu.vue'
import ListboxOption from '@hypefactors/shared/js/components/ui/ListboxOption.vue'
import KebabMenuIcon from '@hypefactors/shared/js/components/ui/icons/KebabMenuIcon.vue'
import EntityTypePicker from './EntityTypePicker.vue'
import EntityPicker from './EntityPicker.vue'

function produce (state, recipe) {
  const draft = JSON.parse(JSON.stringify(state))
  const nextState = recipe(draft)
  return nextState === undefined ? draft : nextState
}

export default {
  components: {
    ListboxControl,
    ListboxButton,
    ListboxMenu,
    ListboxOption,
    KebabMenuIcon,
    EntityTypePicker,
    EntityPicker
  },
  model: {
    prop: 'keyphrase',
    event: 'change'
  },
  props: {
    cardinality: {
      type: Number,
      default: undefined
    },
    keyphrase: {
      type: Object,
      default: () => ({ keyword: '', type: 'entities_ALL' })
    }
  },
  computed: {
    keyword: {
      get () {
        return this.keyphrase.keyword
      },
      set (value) {
        this.$emit('change', produce(this.keyphrase, (draft) => { draft.keyword = value }))
      }
    },
    type: {
      get () {
        return this.keyphrase.type
      },
      set (value) {
        this.$emit('change', produce(this.keyphrase, (draft) => { draft.type = value }))
      }
    }
  },
  methods: {
    focus () {
      this.$refs.entityPicker.focus()
    },
    onAction (action) {
      switch (action) {
        case 'clone':
          this.$emit('clone', this.keyphrase)
          break
        case 'delete':
          this.$emit('delete', this.keyphrase.id)
          break
      }
    }
  }
}
</script>
