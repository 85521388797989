<template>
  <div class="ManageStreams">
    <div class="columns">
      <div class="column is-9">
        <heading
          :size="2"
          serif
          class="m-t-m m-b-l"
        >
          {{ pageTitle }}
        </heading>
        <section>
          <form v-loading="isLoading" @submit.prevent="submitForm">
            <el-collapse
              v-model="openedSections"
              class="ManageStreams-accordion"
              @change="handleSectionOpen"
            >
              <manage-medias
                v-model="$v.form.source_types.$model"
                :v="$v.form.source_types"
                :form="form"
                @change="trackChangeMediaTypeIntent"
                @show-upgrade-dialog="upgradeDialog = true"
              />
              <manage-definitions>
                <div class="columns">
                  <div class="column is-2">
                    <p class="m-b-m">
                      {{ $t('pages.streams.manage_streams.collapse_item_basic_information_description_1') }}
                    </p>
                    <p>
                      {{ $t('pages.streams.manage_streams.collapse_item_basic_information_description_2') }}
                    </p>
                  </div>
                  <div class="column is-10">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <form-field
                          :validator="$v.form.title"
                          :label="$t('forms.stream_name_label')"
                          attribute="general.stream_name"
                          class="field__streamTitle"
                        >
                          <input
                            v-model="form.title"
                            class="input"
                            type="text"
                            name="stream_title"
                            @input="$v.form.title.$touch()"
                          >
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :validator="$v.form.main_keyphrases"
                          :label="$t('pages.streams.manage_streams.main_search_term')"
                          class="field__mainSearchTerm"
                        >
                          <keyphrase-searches v-model="form.main_keyphrases" :cardinality="1" :stored-keyphrases="storedMainKeyphrases" />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.social_hashtags')"
                          class="field__socialHashtags"
                        >
                          <tags-list v-model="form.social_hashtags" :disabled="!anySocialTagsSelected" />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field :label="'Enable alternative spellings?'">
                          <el-switch
                            v-model="alternativeKeyphrasesEnabled"
                            :inactive-text="$t('general.off')"
                            :active-text="$t('general.on')"
                            class="EnableAlternativeSpellings"
                          />
                        </form-field>
                      </div>
                      <div v-show="alternativeKeyphrasesEnabled" class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.alternative_spelling')"
                          class="field__alternativeSpellings"
                        >
                          <keyphrase-searches v-model="form.alternative_keyphrases" :stored-keyphrases="storedAlternativeKeyphrases" />
                        </form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </manage-definitions>
              <manage-additional-info>
                <div class="columns">
                  <div class="column is-2">
                    <p>
                      {{ $t('pages.streams.manage_streams.collapse_item_additional_information_description') }}
                    </p>
                  </div>
                  <div class="column is-10">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.require_all_words')"
                          class="field__searchResultHasTheseWords"
                        >
                          <tags-list
                            v-model="form.search_result_has_these_words"
                            :validator="phrasesValidator.required"
                            name="required_words"
                          />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.require_any_words')"
                          class="field__searchResultHasAnyOfTheseWords"
                        >
                          <tags-list
                            v-model="form.search_result_has_any_of_these_words"
                            :validator="phrasesValidator.optional"
                            name="optional_words"
                          />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.exlude_any_words')"
                          class="field__searchResultNotHasTheseWords"
                        >
                          <tags-list
                            v-model="form.search_result_not_has_these_words"
                            :validator="phrasesValidator.excluded"
                            name="exclude_words"
                          />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.monitored_countries')"
                          class="MonitoredCountries"
                        >
                          <country-picker
                            v-model="form.countries"
                            :allowed="allowedCountries"
                            :exclude="form.excluded_countries"
                            :placeholder="$t('general.all_countries')"
                            multiple
                            popper-class="MonitoredCountries__modal"
                          />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.excluded_countries')"
                          class="ExcludedCountries"
                        >
                          <country-picker
                            v-model="form.excluded_countries"
                            :allowed="allowedCountries"
                            :exclude="form.countries"
                            :placeholder="$t('pages.streams.manage_streams.excluded_countries_placeholder')"
                            popper-class="ExcludedCountries__modal"
                            multiple
                          />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.monitored_languages')"
                          class="MonitoredLanguages"
                        >
                          <language-picker
                            v-model="form.languages"
                            :exclude="form.excluded_languages"
                            :placeholder="$t('general.all_languages')"
                            popper-class="MonitoredLanguages__modal"
                            multiple
                          />
                        </form-field>
                      </div>
                      <div class="column is-12">
                        <form-field
                          :label="$t('pages.streams.manage_streams.excluded_languages')"
                          class="ExcludedLanguages"
                        >
                          <language-picker
                            v-model="form.excluded_languages"
                            :exclude="form.languages"
                            :placeholder="$t('pages.streams.manage_streams.excluded_languages_placeholder')"
                            popper-class="ExcludedLanguages__modal"
                            multiple
                          />
                        </form-field>
                      </div>
                      <div
                        v-if="copyId || !isCreatingNew"
                        class="column is-12"
                      >
                        <form-field :label="$t('pages.streams.manage_streams.blocked_outlets')">
                          <tags-list
                            v-model="form.blacklist"
                            :formatter="(tag) => tag.label"
                            remove-only
                            class="blockedOutletsField"
                          />
                        </form-field>
                        <form-field :label="$t('pages.streams.manage_streams.whitelisted_outlets')">
                          <tags-list
                            v-model="form.whitelist"
                            class="whitelistedOutletsField"
                          />
                        </form-field>
                      </div>
                      <template v-if="isStaffMember">
                        <div class="column is-12">
                          <form-field label="Topics">
                            <basic-select
                              v-model="form.topics"
                              :values="topics"
                              value-prop="value"
                              key-prop="value"
                              label-prop="label"
                              name="topics"
                              multiple
                            />
                          </form-field>
                        </div>
                        <div class="column is-4">
                          <form-field label="Environmental">
                            <basic-select
                              v-model="form.esg_environmental"
                              :values="environmental"
                              value-prop="value"
                              key-prop="value"
                              label-prop="label"
                              name="esg_environmental"
                              multiple
                            />
                          </form-field>
                        </div>
                        <div class="column is-4">
                          <form-field label="Social">
                            <basic-select
                              v-model="form.esg_social"
                              :values="social"
                              value-prop="value"
                              key-prop="value"
                              label-prop="label"
                              name="esg_social"
                              multiple
                            />
                          </form-field>
                        </div>
                        <div class="column is-4">
                          <form-field label="Governance">
                            <basic-select
                              v-model="form.esg_governance"
                              :values="governance"
                              value-prop="value"
                              key-prop="value"
                              label-prop="label"
                              name="esg_governance"
                              multiple
                            />
                          </form-field>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </manage-additional-info>
              <el-collapse-item
                :title="$t('general.advanced')"
                :name="3"
                class="ManageStreams-section ManageAdvancedInfo"
              >
                <div class="columns">
                  <div class="column is-2">
                    {{ $t('pages.streams.manage_streams.collapse_item_advanced_info_description') }}
                  </div>
                  <div class="column">
                    <div class="m-b-l">
                      <div class="is-uppercase m-b-s">
                        {{ $t('pages.streams.manage_streams.advanced_info.block_ads.title') }}
                      </div>
                      <el-switch v-model="form.block_ads" :inactive-text="$t('general.off')" :active-text="$t('general.on')" />
                    </div>
                    <div class="m-b-l">
                      <div class="is-uppercase m-b-s">
                        {{ $t('pages.streams.manage_streams.advanced_info.auto_rss.title') }}
                      </div>
                      <form-field
                        #default="{ validator }"
                        :validator="$v.form.auto_rss"
                        :messages="{ web_only: $t('pages.streams.manage_streams.advanced_info.auto_rss.validation') }"
                      >
                        <el-switch
                          v-model="validator.$model"
                          :inactive-text="$t('general.off')"
                          :active-text="$t('general.on')"
                          data-testid="autoRSSToggle"
                        />
                      </form-field>
                      <div v-if="form.auto_rss && !isCreatingNew" class="is-flex is-aligned-middle">
                        {{ $t('pages.streams.manage_streams.advanced_info.auto_rss.description') }}
                        <a
                          :href="rssFeed"
                          target="_blank"
                          class="button is-primary is-plain is-narrow"
                        >
                          <v-icon type="feed" />
                        </a>
                      </div>
                    </div>
                    <div class="m-b-m">
                      <div class="is-uppercase m-b-s">
                        {{ $t('pages.streams.manage_streams.advanced_info.search_only_show_exact_matches.title') }}
                      </div>
                      <el-switch v-model="form.search_only_show_exact_matches" :inactive-text="$t('general.off')" :active-text="$t('general.on')" />
                    </div>
                    <div class="m-b-m">
                      <div class="is-uppercase m-b-s">
                        {{ $t('pages.streams.manage_streams.advanced_info.near_words_search.title') }}
                      </div>
                      <manage-streams-near-words-search
                        v-for="(value, index) in form.search_where_words_are_near"
                        :key="index"
                        :value="value"
                        :validator="$v.form.search_where_words_are_near.$each[index]"
                        @remove="handleRemove(index)"
                      />
                      <v-button
                        class="is-primary"
                        data-testid="NearWordsSearch__AddMore"
                        type="button"
                        @click="addOneMoreSearchNearWords"
                      >
                        <span class="icon">
                          <v-icon type="plus" />
                        </span>
                        <span>{{ $t('pages.streams.manage_streams.advanced_info.near_words_search.add_one_more') }}</span>
                      </v-button>
                    </div>

                    <div class="column is-12">
                      <heading size="4">
                        {{ $t('pages.streams.manage_streams.advanced_info.headline_search') }}
                      </heading>
                      <div class="columns">
                        <div class="column">
                          <form-field>
                            <tags-list v-model="form.headline_search" />
                          </form-field>
                        </div>
                      </div>
                    </div>

                    <div class="column is-12">
                      <heading size="4">
                        {{ $t('pages.streams.manage_streams.auto_clippings') }}
                      </heading>
                      <div class="columns">
                        <div class="column">
                          <el-checkbox
                            v-model="form.auto_clipping"
                            :disabled="!authorizeBool('clipping.create')"
                            data-testid="EnableAutoClipping"
                          >
                            {{ $t('pages.streams.manage_streams.enable_autoclipping') }}
                          </el-checkbox>
                        </div>
                        <div class="column">
                          <form-field :label="$t('pages.streams.manage_streams.auto_tags')">
                            <tags-list
                              v-model="form.auto_tags"
                              :disabled="!(authorizeBool('clipping.create') && form.auto_clipping)"
                              name="auto_tags"
                              data-testid="AutoTagsInput"
                            />
                          </form-field>
                          <form-field
                            v-if="authorizeBool('kpis.list')"
                            :label="$t('pages.streams.manage_streams.auto_kpis')"
                          >
                            <basic-select
                              v-model="form.auto_kpi_campaigns"
                              :values="kpi_campaigns"
                              :disabled="!form.auto_clipping"
                              value-prop="id"
                              key-prop="id"
                              label-prop="name"
                              name="auto_tags"
                              multiple
                              data-testid="AutoKPIsSelect"
                            />
                          </form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-collapse-item>
              <div class="buttons">
                <template v-if="!isCreatingNew">
                  <restricted-button
                    :disabled="!!copyId || isCreatingNew || form.isLoading"
                    :loading="isDeleting"
                    class="m-r-s"
                    permission="stream.delete"
                    action-class="button is-plain is-primary Stream-item__delete"
                    data-testid="DeleteStreamButton"
                    @click="remove"
                  >
                    {{ $t('general.delete') }}
                  </restricted-button>
                  <restricted-button
                    :disabled="copyId || isCreatingNew"
                    :title="$t('components.streams.stream_item.move_stream')"
                    tag="a"
                    permission="stream.update"
                    action-class="Stream-item__action button is-outlined is-dark"
                    data-testid="ChangeBrandButton"
                    @click.prevent="openChangeBrandModal"
                  >
                    {{ $t('pages.streams.manage_streams.change_brand') }}
                  </restricted-button>
                  <router-link
                    v-if="showCopyStreamLink"
                    :title="$t('components.streams.stream_item.copy_stream')"
                    :to="{ name: 'streams.create', query: { copy: form.id } }"
                    :disabled="isDeleting || form.isLoading"
                    class="button is-text"
                    data-testid="copyStream"
                  >
                    <v-icon type="clone" />
                    <span>{{ $t('forms.clone') }}</span>
                  </router-link>
                </template>
                <router-link
                  :to="cancelUrl"
                  :disabled="isDeleting"
                  class="button is-text m-l-a"
                  data-testid="cancelStream"
                >
                  {{ $t('forms.cancel') }}
                </router-link>
                <v-button
                  :loading="form.isLoading"
                  :disabled="isDeleting"
                  class="is-primary"
                  type="button"
                  data-testid="saveStream"
                  @click="submitForm"
                >
                  {{ $t('forms.save') }}
                </v-button>
              </div>
            </el-collapse>
          </form>
        </section>
      </div>
      <div class="column is-3">
        <div class="is-position-sticky z-index-1 p-t-l">
          <heading size="4" :serif="false">
            {{ $t('pages.streams.manage_streams.sidebar_charts_title') }}
          </heading>
          <stream-sidebar-charts :stream-body="sidebarChartsQuery" />
        </div>
      </div>
    </div>
    <stream-brand-change-modal
      v-if="!isCreatingNew"
      :visible.sync="changeBrandVisible"
      :stream-id="form.id"
      @change="handleBrandChange"
    />
    <el-dialog
      :visible.sync="upgradeDialog"
      class="el-dialog--is-paddingless is-fullscreen-mobile"
      width="90%"
      custom-class="UpgradeNowDialog"
    >
      <upgrade-now-notice :upgrade-url="{ name: 'cx.monitor' }" type="stream_create" />
      <fake-data-ribbon>
        <fake-data-image-placeholder type="stream_create" />
      </fake-data-ribbon>
    </el-dialog>
  </div>
</template>

<script>
import { withParams } from 'vuelidate'
import { mapActions, mapGetters } from 'vuex'

import Form from '@/services/forms/Form'
import { required, maxLength, minValue, numeric } from 'vuelidate/lib/validators'
import { maxWordCount } from '@hypefactors/shared/js/utils/validation'

import { StreamsApiService } from '@hypefactors/shared/js/services/api/StreamsService'
import { SOCIAL_MEDIA_TYPES } from '@hypefactors/shared/js/constants/mediaTypes'

import TagsList from '@/components/core/TagsList'
import ManageMedias from '@/components/streams/ManageMedias'
import KeyphraseSearches from '@/components/streams/KeyphraseSearches'
import ManageDefinitions from '@/components/streams/ManageDefinitions'
import ManageAdditionalInfo from '@/components/streams/ManageAdditionalInfo'
import StreamBrandChangeModal from '@/components/streams/StreamBrandChangeModal'
import ManageStreamsNearWordsSearch from '@/components/streams/ManageStreamsNearWordsSearch'
import StreamSidebarCharts from '@hypefactors/shared/js/components/streams/StreamSidebarCharts'

const streamForm = () => new Form({
  id: { value: '' },
  title: { value: '', rules: { required } },
  source_types: {
    value: [],
    rules: {
      required,
      canMonitorFacebook: withParams({ filter_word_count: 30, hashtags_count: 5 }, function (value, form) {
        return value.includes('facebook') ? (this.keywordsFiltersWordCount <= 30 && form.social_hashtags.length <= 5) : true
      }),
      canMonitorTwitter: withParams({ max_characters: 1000 }, function (value, form) {
        return value.includes('twitter') ? this.searchQueryLength <= 1000 : true
      }),
      canMonitorInstagram: withParams({ hashtags_count: 5 }, (value, form) => {
        return value.includes('instagram') ? form.social_hashtags.length <= 5 : true
      })
    }
  },
  main_keyphrases: { value: [], rules: { required, maxLength: maxLength(1) } },
  alternative_keyphrases: { value: [] },
  social_hashtags: { value: [] },
  blacklist: { value: [] },
  whitelist: { value: [], rules: {} },
  countries: { value: [] },
  excluded_countries: { value: [] },
  languages: { value: [] },
  excluded_languages: { value: [] },
  search_result_has_these_words: { value: [] },
  search_result_not_has_these_words: { value: [] },
  search_result_has_any_of_these_words: { value: [] },
  brand_id: { value: '' },
  headline_search: { value: [] },
  auto_clipping: { value: false },
  auto_rss: {
    value: false,
    rules: {
      web_only (value, parent) {
        return !value || (parent.source_types.length === 1 && parent.source_types[0] === 'web')
      }
    }
  },
  block_ads: { value: true },
  topics: { value: [] },
  esg_environmental: { value: [] },
  esg_social: { value: [] },
  esg_governance: { value: [] },
  auto_tags: { value: [] },
  search_where_words_are_near: {
    value: [],
    rules: {
      $each: {
        term1: { required, max_word_count: maxWordCount(1) },
        term2: { required, max_word_count: maxWordCount(1) },
        distance: { minValue: minValue(1), numeric }
      }
    }
  },
  search_only_show_exact_matches: {
    value: false,
    rules: {}
  },
  auto_kpi_campaigns: {
    value: [],
    rules: {}
  }
})

export default {
  components: {
    StreamSidebarCharts,
    ManageStreamsNearWordsSearch,
    StreamBrandChangeModal,
    ManageMedias,
    KeyphraseSearches,
    ManageDefinitions,
    ManageAdditionalInfo,
    TagsList
  },

  props: {
    streamId: {
      type: String,
      default: ''
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  data () {
    return {
      form: streamForm(),
      openedSections: [0],
      isLoading: false,
      isDeleting: false,
      upgradeDialog: false,
      changeBrandVisible: false,
      kpi_campaigns: [],
      storedMainKeyphrases: [],
      alternativeKeyphrasesEnabled: false,
      storedAlternativeKeyphrases: [],
      phrasesValidator: {
        required: {
          max_word_count: maxWordCount(4, ',')
        },
        optional: {
          max_word_count: maxWordCount(4, ',')
        },
        excluded: {
          max_word_count: maxWordCount(4, ',')
        }
      }
    }
  },

  computed: {
    ...mapGetters(['isStaffMember']),

    isCreatingNew () {
      return !this.streamId
    },

    copyId () {
      return this.$route.query['copy']
    },

    pageTitle () {
      if (this.copyId) {
        return this.$t('pages.streams.manage_streams.copying_heading', { name: this.form.title })
      } else if (this.streamId) {
        return this.$t('pages.streams.manage_streams.editing_heading', { name: this.form.title })
      } else {
        return this.$t('pages.streams.manage_streams.heading')
      }
    },

    searchQueryLength () {
      return [
        // search term is not an array so we can just leave it as is
        ...this.form.main_keyphrases.map(keyphrase => keyphrase['keyword']),
        ...this.form.social_hashtags,
        ...this.form.alternative_keyphrases.map(keyphrase => keyphrase['keyword']),
        ...this.form.search_result_has_these_words,
        ...this.form.search_result_not_has_these_words,
        ...this.form.search_result_has_any_of_these_words
      ].join().length
    },
    keywordsFiltersWordCount () {
      return [
        ...this.form.search_result_has_these_words,
        ...this.form.search_result_not_has_these_words,
        ...this.form.search_result_has_any_of_these_words
      ].length
    },

    showCopyStreamLink () {
      return this.authorizeBool('stream.create')
    },

    anySocialTagsSelected () {
      return this.form.source_types.some(item => SOCIAL_MEDIA_TYPES.includes(item))
    },

    rssFeed () {
      return this.$getUrl(`/streams/${this.streamId}/rss`, 'api')
    },

    cancelUrl () {
      return !this.isCreatingNew
        ? { name: 'streams.mentions', params: { streamId: this.streamId } }
        : { name: 'streams.list' }
    },

    sidebarChartsQuery () {
      return this.$v.form.$invalid ? {} : this.form.data()
    },

    countriesPermissions () {
      return this.authorize('stream.countries')
    },

    allowedCountries () {
      return this.countriesPermissions.only || []
    },

    topics () {
      return [
        {
          value: 'SOC',
          label: 'Society and Culture'
        },
        {
          value: 'SCI',
          label: 'Science and Mathematics'
        },
        {
          value: 'FIT',
          label: 'Health'
        },
        {
          value: 'EDU',
          label: 'Education and References'
        },
        {
          value: 'COM',
          label: 'Computers and Internet'
        },
        {
          value: 'SPO',
          label: 'Sports'
        },
        {
          value: 'BIZ',
          label: 'Business and Finance'
        },
        {
          value: 'ENT',
          label: 'Entertainment and Music'
        },
        {
          value: 'FAM',
          label: 'Family and Relationships'
        },
        {
          value: 'GOV',
          label: 'Politics and Government'
        }
      ]
    },

    environmental () {
      return [
        {
          value: 'AIRQU',
          label: 'Air quality'
        },
        {
          value: 'CLIMA',
          label: 'Physical impacts of climate change'
        },
        {
          value: 'ECOLO',
          label: 'Ecological impacts'
        },
        {
          value: 'EMISS',
          label: 'GHG emissions'
        },
        {
          value: 'ENGRY',
          label: 'Energy management'
        },
        {
          value: 'MATER',
          label: 'Waste and hazardous materials management'
        },
        {
          value: 'WATER',
          label: 'Water and wastewater management'
        }
      ]
    },

    social () {
      return [
        {
          value: 'ACCES',
          label: 'Access and affordability'
        },
        {
          value: 'COMPE',
          label: 'Competitive behavior'
        },
        {
          value: 'CUSTO',
          label: 'Customer welfare'
        },
        {
          value: 'DIVER',
          label: 'Employee engagement, inclusion and diversity'
        },
        {
          value: 'ETHIC',
          label: 'Business ethics'
        },
        {
          value: 'HEALT',
          label: 'Employee health and safety'
        },
        {
          value: 'LABOR',
          label: 'Labor practices'
        },
        {
          value: 'PRIVA',
          label: 'Customer privacy'
        },
        {
          value: 'QUALI',
          label: 'Product quality and safety'
        },
        {
          value: 'RIGHT',
          label: 'Human rights and community relations'
        },
        {
          value: 'SELLI',
          label: 'Selling practices and product labeling'
        }
      ]
    },

    governance () {
      return [
        {
          value: 'DIREC',
          label: 'Director removal'
        },
        {
          value: 'INCID',
          label: 'Critical incident risk management'
        },
        {
          value: 'LEGAL',
          label: 'Management of legal and regulatory framework'
        },
        {
          value: 'LIFEC',
          label: 'Product design and lifecycle management'
        },
        {
          value: 'MODEL',
          label: 'Business model resilience'
        },
        {
          value: 'SECUR',
          label: 'Data security'
        },
        {
          value: 'SUPPL',
          label: 'Supply chain management'
        },
        {
          value: 'SYSTM',
          label: 'Systemic risk management'
        }
      ]
    }
  },

  watch: {
    activeBrandId: 'handleBrandChanged',
    'form.alternative_keyphrases': function (val) {
      if (!val.length) return
      this.alternativeKeyphrasesEnabled = true
    },
    'form.source_types': function (val) {
      if (!val.length) return (this.openedSections = [0])
      this.openAllSections()
    }
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!to.query.copy && !to.params.streamId) return
      vm.getStream()
      vm.fetchKpis()
    })
  },

  async beforeRouteUpdate (to, from, next) {
    if (!to.query.copy && !to.params.streamId) {
      return next()
    }
    try {
      await Promise.all([this.getStream(), this.fetchKpis()])
      next()
    } catch (err) {
      this.$notify.error({
        title: this.$t('general.error'),
        message: this.$t('errors.error_occurred')
      })
      this.$handleError(err)
      this.$router.push({ name: 'streams.mentions', params: { streamId: to.query.copy || to.params.streamId } })
    }
  },

  async beforeRouteLeave (to, from, next) {
    // If we are editing but the user decided to switch brands and accepted the warning
    if (!this.isCreatingNew && this.form.brand_id !== this.activeBrandId) return next()

    // Check if there are any changes on the Streams form
    if (this.form.hasChanges()) {
      try {
        await this.$confirm(this.$t('pages.streams.manage_streams.confirm_cancelling'), {
          customClass: 'ConfirmStreamCancelDialog'
        })
      } catch (err) {
        /* return so we dont reset the stream */
        return
      }
    }
    // Reset the stream
    this.form.reset()
    this.$v.$reset()
    this.storedMainKeyphrases = []
    this.alternativeKeyphrasesEnabled = false
    this.storedAlternativeKeyphrases = []
    next()
  },

  methods: {
    ...mapActions(['setActiveBrand', 'incPermissionLimits', 'decPermissionLimits']),

    getPreselectedEsgValues (data, preSelected) {
      return data.filter(obj => preSelected.includes(obj.value)).map(obj => obj.value)
    },

    getStream () {
      this.isLoading = true
      const streamId = this.copyId || this.streamId
      return StreamsApiService.get(streamId, {
        params: {
          include: 'brand,auto_kpi_campaigns'
        }
      })
        .then((stream) => {
          this.storedMainKeyphrases = stream.main_keyphrases
          this.storedAlternativeKeyphrases = stream.alternative_keyphrases
          return stream
        })
        .then((stream) => {
          console.log(this.getPreselectedEsgValues(this.environmental, stream.esg))
          this.form.merge({
            ...stream,
            auto_kpi_campaigns: stream.auto_kpi_campaigns.data.map(kpi => kpi.id),
            brand_id: stream.brand.data.id,
            esg_environmental: this.getPreselectedEsgValues(this.environmental, stream.esg),
            esg_social: this.getPreselectedEsgValues(this.social, stream.esg),
            esg_governance: this.getPreselectedEsgValues(this.governance, stream.esg)
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    submitForm () {
      this.$v.$touch()
      if (this.$v.$error) return this.$notify.warning(this.$t('warnings.invalid_fields'))
      this.isCreatingNew ? this.createStream() : this.updateStream()
    },
    createStream () {
      this.form.setPayloadTransformer((data) => {
        data.brand_id = this.activeBrandId
        data.alternative_keyphrases = this.alternativeKeyphrasesEnabled ? data.alternative_keyphrases : []

        data.esg = [
          ...data.esg_environmental,
          ...data.esg_social,
          ...data.esg_governance
        ]

        delete data.esg_environmental
        delete data.esg_social
        delete data.esg_governance

        return data
      })
      this.form.submit(StreamsApiService.create)
        .then((response) => {
          this.$notify.success({
            title: this.$t('general.success'),
            message: this.$t('success.stream_created')
          })
          this.incPermissionLimits({ permission: 'stream.create' })
          this.$v.$reset()
          this.$router.push({ name: 'streams.mentions', params: { streamId: response.data.id } })
        })
        .catch(e => {
          this.$displayRequestError(e)
        })
    },
    updateStream () {
      this.form.setPayloadTransformer((data) => {
        data.alternative_keyphrases = this.alternativeKeyphrasesEnabled ? data.alternative_keyphrases : []

        data.esg = [
          ...data.esg_environmental,
          ...data.esg_social,
          ...data.esg_governance
        ]

        delete data.esg_environmental
        delete data.esg_social
        delete data.esg_governance

        return data
      })
      return this.form.patch(`streams/${this.form.id}?include=brand`)
        .then(() => {
          this.$notify.success({
            title: this.$t('general.success'),
            message: this.$t('success.stream_updated')
          })
          this.$router.push({ name: 'streams.mentions', params: { streamId: this.form.id } })
        })
        .catch(e => {
          this.$displayRequestError(e)
        })
    },
    openAllSections () {
      this.openedSections = Array.from(Array(4).keys())
    },
    handleSectionOpen (val) {
      this.form.source_types.length === 0 && (this.openedSections = [0])
    },

    /**
     * Handles brand change while creating/editing a stream
     * @param newBrand
     * @param oldBrand
     * @returns {Promise<*>}
     */
    async handleBrandChanged (newBrand, oldBrand) {
      // If we are creating a new stream, we just notify the user.
      if (this.isCreatingNew) {
        return this.$notify.info(this.$t('pages.streams.manage_streams.notify_stream_create_in_switched_brand'))
      }

      // If the new brand is the same as the one on the Stream, its fine (usually because we just reverted to it).
      if (newBrand === this.form.brand_id) return

      // If the stream has changes, notify the user
      if (this.form.hasChanges()) {
        try {
          await this.$confirm(this.$t('pages.streams.manage_streams.notify_cannot_change_brand_while_editing'))
        } catch (err) {
          // user does not want to continue, we revert back to the old brand
          this.$notify.info(this.$t('pages.streams.manage_streams.reverting_brand'))
          return this.setActiveBrand(oldBrand)
        }
      }
      // go to the brand_mentions of the new brand
      this.$notify.info(this.$t('pages.streams.manage_streams.notify_brand_change_redirect_to_streams'))
      this.$router.push({ name: 'streams.list' })
    },

    async remove () {
      try {
        await this.$confirm(this.$t('components.streams.stream_item.confirm_remove_stream'))
        this.isDeleting = true

        await StreamsApiService.delete(this.streamId)

        await this.decPermissionLimits({ permission: 'stream.create' })

        this.$router.push({ name: 'streams.list' })

        this.$notify({
          title: this.$t('general.success'),
          message: this.$t('success.stream_removed'),
          type: 'success'
        })
      } catch (e) {
        if (e === 'cancel') return
        this.$displayRequestError(e, this.$t('errors.cannot_remove_stream', { message: e.message }))
      } finally {
        this.isDeleting = false
      }
    },

    handleBrandChange (newBrandId) {
      this.form.merge({
        brand_id: newBrandId
      })
      this.setActiveBrand(newBrandId)
    },

    openChangeBrandModal () {
      this.changeBrandVisible = true
    },

    addOneMoreSearchNearWords () {
      this.form.search_where_words_are_near.push({
        term1: '',
        term2: '',
        distance: 1
      })
    },

    handleRemove (index) {
      this.form.search_where_words_are_near.splice(index, 1)
    },
    // eslint-disable-next-line camelcase
    trackChangeMediaTypeIntent ({ media_type, is_activated }) {
      this.$ma.trackEvent({
        action: 'Stream Edit Media Type Intent',
        properties: {
          streamName: this.form.title,
          streamId: this.form.id,
          mediaType: media_type,
          isActivated: is_activated
        }
      })
    },

    async fetchKpis () {
      if (!this.authorizeBool('kpis.list')) return
      const params = {
        params: {
          brands: [this.activeBrandId]
        }
      }
      try {
        this.kpi_campaigns = await this.$api.getData('kpi-campaigns', params)
      } catch (e) { this.$displayRequestError(e) }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.ManageStreams-section.ManageDefinitions .el-collapse-item__wrap {
  overflow: visible;
}

.ManageStreams {
  position: relative !important;

  &-accordion {
    border: none;
  }

  &-section {
    margin-bottom: 2rem;

    .el-collapse-item__header {
      background: $hf__color-gray-darker;
      color: $white !important; // important removes the focus color change
      padding: 0 $padding;
      font-size: $size-5;
      @include rtl {
        .el-collapse-item__arrow {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }

    .el-collapse-item__content {
      padding-bottom: 0;

      p {
        line-height: 1.5rem;
      }
    }

    .el-collapse-item__wrap {
      padding: $padding;
      border: none;
      background: $white;
    }
  }

  &-media {
    cursor: pointer;

    &__icon {
      background: $hf__color-gray-darker;
      color: $white;
      transition: 0.1s ease-in;
      position: relative;
    }

    &__token-warning {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%, -25%);
    }

    &.is-selected {
      color: $hf__color-primary;

      .ManageStreams-media__icon {
        background: $hf__color-primary;
      }
    }

    &.is-disabled {
      cursor: not-allowed;
      opacity: .5;

      &:hover .ManageStreams-media__icon {
        background: $dark;
      }
    }

    &:hover {
      color: $hf__color-primary--alternative;

      .ManageStreams-media__icon {
        background: $hf__color-primary--alternative;
      }
    }
  }
}
</style>
