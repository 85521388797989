<template>
  <QuestionIcon v-if="type === 'entities_ALL'" />
  <UserIcon v-else-if="type === 'entities_PERS'" />
  <PackageIcon v-else-if="type === 'entities_PROD'" />
  <BuildingIcon v-else-if="type === 'entities_ORG'" />
  <LocationIcon v-else-if="type === 'entities_LOC'" />
  <CalendarIcon v-else-if="type === 'entities_EVENT'" />
  <SearchIcon v-else-if="type === undefined" />
</template>
<script>
import QuestionIcon from '@hypefactors/shared/js/components/ui/icons/QuestionIcon.vue'
import UserIcon from '@hypefactors/shared/js/components/ui/icons/UserIcon.vue'
import PackageIcon from '@hypefactors/shared/js/components/ui/icons/PackageIcon.vue'
import BuildingIcon from '@hypefactors/shared/js/components/ui/icons/BuildingIcon.vue'
import LocationIcon from '@hypefactors/shared/js/components/ui/icons/LocationIcon.vue'
import CalendarIcon from '@hypefactors/shared/js/components/ui/icons/CalendarIcon.vue'
import SearchIcon from '@hypefactors/shared/js/components/ui/icons/SearchIcon.vue'

export default {
  components: {
    QuestionIcon,
    UserIcon,
    PackageIcon,
    BuildingIcon,
    LocationIcon,
    CalendarIcon,
    SearchIcon
  },
  props: {
    type: {
      type: String,
      default: undefined
    }
  }
}
</script>
